import React, { Component } from 'react';
import { Form, Input, Button, Card, message } from 'antd';

import PublicApi from '../api/public';

import 'antd/dist/antd.css';

export default class Index extends Component {

	constructor(props){
		super(props);
		let jwtToken = window.localStorage.getItem('projectJwtToken');
	    if (jwtToken) {
	        window.location.href = '/list'
	    }
	}

	render(){

		const layout = {
			labelCol: {
				span: 8,
			},
			wrapperCol: {
				span: 12,
			},
		};

		const tailLayout = {
			wrapperCol: {
				offset: 8,
				span: 16,
			},
		};

		const login = ( values ) => {
			// 接口
			PublicApi.login( response => {
				if(response && response.data && response.data.status == "ok"){
					// jwtToken
					let jwtToken = response.data.token;
					let sale_id = response.data.sale_id;
					window.localStorage.setItem('projectJwtToken', jwtToken);
					window.localStorage.setItem('sale_id', sale_id);
					message.success({
					    content: '登录成功',
					    style: {
					      	marginTop: '20vh',
					    },
					    duration: 1,
					}).then(() => {
				    	window.location.href = "/list";
				    });
				}else{
					message.error({
					    content: '登录失败',
					    style: {
					      	marginTop: '20vh',
					    },
					    duration: 1,
					});
				}

			}, values);
		}

		const onFinish = (values) => {
			message.loading({
			    content: '正在登录中..',
			    style: {
			      	marginTop: '20vh',
			    },
			    duration: 1,
			}).then(() => {
		    	login(values)
		    });
		};

		return (
			<div id="index">
                <div class="login">
					<Card>
						<Form
					      {...layout}
					      onFinish={onFinish}
					    >
							<Form.Item
								label="手机号"
								name="mobile"
								class="mobile"
								rules={[{ required: false, message: '请输入手机号!' }]}
							>
								<Input />
							</Form.Item>

							<div class="password">
								<Form.Item
									label="密码"
									name="password"
									class="password"
									rules={[{ required: false, message: '请输入密码!' }]}
								>
									<Input.Password />
								</Form.Item>
							</div>

							<div class="index_button">
								<Form.Item {...tailLayout}>
									<Button htmlType="submit">
										登录
									</Button>
								</Form.Item>
							</div>
					    </Form>
				    </Card>
			    </div>
			</div>
		)
	}
}