import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import { Layout } from 'antd';


import Index from './page/Index';
import List from './page/List';
import Info from './page/Info';
import Home from './page/Home';

const { Header, Content, Footer} = Layout;

class App extends Component {
    render() {
        return (
            <Layout className="layout " style={{ height: '100%' }}>
            	<Header></Header>
                <Content style={{ padding: '50px'}}>
                    <Route exact path="/" component={Index}/>
                    <Route exact path="/list" component={List}/>
                    <Route exact path="/Info/:id" component={Info}/>
                    <Route exact path="/Home" component={Home}/>
                </Content>
                <Footer></Footer>
            </Layout>
        );
    }
}

export default App;
