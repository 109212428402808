import { post, get } from './index';

function getPlatformList(func) {
	get('/platformList').then(response => {
        if(response){
            return func(response.data)
        }else{
            return false
        }
    });
}

function getProjectList(func, platform_id) {
    get('/projectList?platform_id=' + platform_id).then(response => {
        if(response){
            return func(response.data)
        }else{
            return false
        }
    });
}

function getModuleList(func, project_id) {
    get('/moduleList?project_id=' + project_id).then(response => {
        if(response){
            return func(response.data)
        }else{
            return false
        }
    });
}

function submitProject(func, params) {
    post('/export', params).then(response => {
        if(response){
            return func(response.data)
        }else{
            return false
        }
    });
}

export default {
    getPlatformList,
    getProjectList,
    getModuleList,
    submitProject
}