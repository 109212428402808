import { post, get } from './index';

function login(func, params) {
    post('/login', params).then(response => {
        if(response){
            return func(response.data)
        }else{
            return false
        }
    });
}

export default {
    login
}