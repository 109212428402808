import { post, get } from './index';

function exportLog(func) {
    get('/exportLog').then(response => {
        if(response){
            return func(response.data)
        }else{
            return false
        }
    });
}

export default {
    exportLog
}