import React, { Component } from 'react';
import { Input, Image, Typography, Layout, Row, Col } from 'antd';

import infoApi from '../api/info';

import 'antd/dist/antd.css';

const { Title } = Typography;
const { Sider, Content } = Layout;

export default class Info extends Component {

	constructor(props){
		super(props);
		this.state = { 
			list: [],
            info: '',
            sumprice: 0,
            discount: 0,
            taxation: 6,
            changeModuleNum: [],
            export_log_id: this.props.match.params.id,
            sale_id: window.localStorage.getItem('sale_id')
		}
	}

	async componentDidMount() { 
        infoApi.exportLog( response => {
            if ( response !== '' ) {
                this.setState({ info: response.data || '' });
            }

            infoApi.allData( response => {
                let list = [];
                for (let i in response.data) {
                    list[i] = response.data[i]
                    list[i].key = "list_" + i
                    list[i].id = list[i].id + ""
                }
                this.setState({ list: list || '' });
            });
        }, this.state.export_log_id);
	}

	render(){

        var platformData = {}
        var platformBox = []
        var moduleNum = []

        let onChangeNum = (e) => {
			let moduleid = e.target.getAttribute("moduleid")
			let value = e.target.value

            if ( value === "" ) {
                value = 0
            }

            let changeModuleNum = this.state.changeModuleNum
            changeModuleNum[moduleid] = value
            this.setState({ changeModuleNum: changeModuleNum || '' })

            // 
            let sumprice = 0
            for (let j = 0; j < this.state.list.length; j++) {
                let platform = this.state.list[j]
                for (let k = 0; k < platform['project'].length; k++) {
                    let project = platform['project'][k]
                    for (let y = 0; y < project['module'].length; y++) {
                        let module = project['module'][y]

                        if ( changeModuleNum[module.id] >= 0 ) {
                            module.num = changeModuleNum[module.id]
                        }

                        if ( module.num >= 0 ) {
                            module.sum = module.price * module.num
                            module.sum = Math.floor(module.sum * 100) / 100
                            
                            sumprice += module.price * module.num
                        }
                    }
                }
            }

            sumprice = Math.floor(sumprice * 100) / 100 

            this.setState({ sumprice: sumprice || '' })
		}

        // 
        let onChangeDiscount = (e) => {
            let discount = e.target.value
            this.setState({ discount: discount || '' })
		}

        // 
        let onChangeTaxation = (e) => {
            let taxation = e.target.value
            this.setState({ taxation: taxation || '' })
		}
        
        // 已选择数量
        if ( this.state.info !== "" ) {
            let content_num = this.state.info.content_num
            if ( content_num ) {
                content_num = JSON.parse(content_num.replace(/'/g, '"'))
                for(var i in content_num){
                    moduleNum[i] = content_num[i]
                }
            }
        }

        let sumprice = this.state.sumprice
        let is_sumprice = false
        if ( sumprice === 0 ) {
            is_sumprice = true
        }
            
        for (let j = 0; j < this.state.list.length; j++) {
            let platform = this.state.list[j]
            for (let k = 0; k < platform['project'].length; k++) {
                let project = platform['project'][k]
                project['is_module_support'] = true
                for (let y = 0; y < project['module'].length; y++) {
                    let module = project['module'][y]
                    if ( moduleNum[module.id] ) {
                        module.num = moduleNum[module.id]
                        if ( is_sumprice === true ) {
                            module.sum = module.price * module.num
                            sumprice += module.price * module.num
                        }
                    }
                    // 
                    if ( module.support !== "" ) {
                        project['is_module_support'] = true
                    }
                }
            }
        }

        // 
        let taxationSumPirce = 0
        let discountSumPirce = 0
        if ( sumprice > 0 ) {

            let taxation = this.state.taxation
            if ( taxation > 0 ) {
                let taxationPrice = sumprice * taxation / 100
                taxationSumPirce = sumprice + taxationPrice
                taxationSumPirce = Math.floor(taxationSumPirce * 100) / 100 
            }

            // 
            let discount = this.state.discount
            if ( discount > 0 ) {
                discountSumPirce = taxationSumPirce * discount / 100
                discountSumPirce = Math.floor(discountSumPirce * 100) / 100 
            }

        }

        for (let j = 0; j < this.state.list.length; j++) {

            let platform = this.state.list[j]
            let projectBox = []
            for (let k = 0; k < platform['project'].length; k++) {

                let project = platform['project'][k]

                // 单行模式
                if ( project.is_module_support === true ){

                    let content = []

                    for (let y = 0; y < project['module'].length; y++) {

                        let module = project['module'][y]
                        let moduleid = module.id

                        content.push(
                            <Row id="moduleBoxNode" align="middle"  key={module.id} >
                                <Col span={4}>{module.content}</Col>
                                <Col span={4}>{module.standard}</Col>
                                <Col span={4}>{module.support}</Col>
                                <Col span={2}>{module.unit}</Col>
                                <Col span={2}>{module.price}元 / {module.unit}</Col>
                                <Col span={2}><Input moduleid={moduleid} defaultValue={module.num} onChange={onChangeNum} /></Col>
                                <Col span={2}>{module.sum}</Col>
                                <Col span={4}>{module.explain}</Col>
                            </Row>
                        )
                    }

                    projectBox.push(
                        <Layout  id="projectBox" key={project.id} >
                            <Sider id="projectBoxName">
                                <Title level={5}>{project.name}</Title>
                                <Image
                                    width={180}
                                    src={project.image}
                                />
                            </Sider>
                            <Content>
                                {content}
                            </Content>
                        </Layout>
                    )

                // 合并模式
                } else {

                    let moduleBox1 = []
                    let moduleBox2 = []
                    let moduleBox0 = []

                    for (let y = 0; y < project['module'].length; y++) {
                        let module = project['module'][y]
                        moduleBox1.push(
                            <Row id="moduleBoxNode" align="middle">
                                <Col span={10}>{module.content}</Col>
                                <Col span={14}>{module.standard}</Col>
                            </Row>
                        )
                    }

                    for (let y = 0; y < project['module'].length; y++) {
                        let module = project['module'][y]
                        moduleBox0.push(
                            <Row id="moduleBoxNode" align="middle">
                                <Col span={24}>{module.support}</Col>
                            </Row>
                        )
                    }

                    for (let y = 0; y < project['module'].length; y++) {

                        let module = project['module'][y]
                        let moduleid = module.id

                        moduleBox2.push(
                            <Row id="moduleBoxNode" align="middle">
                                <Col span={4}>{module.unit}</Col>
                                <Col span={4}>{module.price}元 / {module.unit}</Col>
                                <Col span={4}><Input moduleid={moduleid} defaultValue={module.num} onChange={onChangeNum} /></Col>
                                <Col span={4}>{module.sum}</Col>
                                <Col span={8}>{module.explain}</Col>
                            </Row>
                        )
                    }

                    projectBox.push(
                        <Layout  id="projectBox" >

                            <Sider id="projectBoxName">
                                <Title level={5}>{project.name}</Title>
                                <Image
                                    width={180}
                                    src={project.image}
                                />
                            </Sider>

                            <Content>
                            <Row  align="middle">
                                <Col span={24} >{moduleBox1}</Col> 
                            </Row>
                            </Content>
                            
                            <Sider id="projectBoxSupport">
                                {project.support}
                            </Sider>

                            <Content>
                                <Row>
                                    <Col span={24} >{moduleBox2}</Col> 
                                </Row>
                            </Content>

                        </Layout>
                    )

                }
                
            }

            platformBox.push(
                <Row id="platformBox" key={platform.id}>
                    <Col span={24}><Title>{platform.name}</Title></Col>
                    <Col span={24}>{projectBox}</Col>
                </Row>
            )
        }

        // 导出
        let onFinish = () => {

            let changeModuleNum = this.state.changeModuleNum

            for (let j = 0; j < this.state.list.length; j++) {
                let platform = this.state.list[j]
                for (let k = 0; k < platform['project'].length; k++) {
                    let project = platform['project'][k]
                    for (let y = 0; y < project['module'].length; y++) {
                        let module = project['module'][y]
                        if ( changeModuleNum[module.id] ) {
                            module.num = changeModuleNum[module.id]
                        }
                    }
                }
            }

			platformData = JSON.stringify(this.state.list)
            document.getElementById("sale_id").value = this.state.sale_id
            document.getElementById("export_log_id").value = this.state.export_log_id
			document.getElementById("platform").value = platformData
		}

        // 保存
        let onSave = () => {
            document.getElementById("is_save").value = 1
            onFinish()
		}
		
		return (

			<div id="info">
                {/* <form action="http://127.0.0.1:8000/export" method="post" > */}
                <form action="https://productquotationapi.fzsdmjh5.com/export" method="post" >

                    <Row id="platformBox">
                        <Col span={24}><Input id="platformBoxInput" name="title" defaultValue="时代魔界MVR真三维数字化展示系统 PC端、iPad端、H5端制作方案及报价" /></Col>
                    </Row>

                    <Layout  id="projectBox" >
                        <Sider id="projectBoxName">
                            <Title level={5}>项目名称</Title>
                        </Sider>
                        <Content>
                            <Row id="moduleBoxNode" align="middle">
                                <Col span={4}><span>制作内容</span></Col>
                                <Col span={4}>制作标准</Col>
                                <Col span={4}>技术支持</Col>
                                <Col span={2}>单位</Col>
                                <Col span={2}>单价</Col>
                                <Col span={2}>数量</Col>
                                <Col span={2}>小计</Col>
                                <Col span={4}>补充说明</Col>
                            </Row>
                        </Content>
                    </Layout>

                    {platformBox}

                    <Row id="platformBox">
                        <Col span={4}>小计</Col>
                        <Col span={20} style={{textAlign: "left"}}>
                            {sumprice}
                        </Col>
                    </Row>

                    <Row id="platformBox">
                        <Col span={4}>税费 ( 单位 % : 默认为 6, 即为 6% ) </Col>
                        <Col span={20}>
                            <Input name="taxation" onChange={onChangeTaxation} defaultValue={this.state.taxation} />
                        </Col>
                    </Row>

                    <Row id="platformBox">
                        <Col span={4}>总价计</Col>
                        <Col span={20} style={{textAlign: "left"}}>
                            {taxationSumPirce}
                        </Col>
                    </Row>

                    <Row id="platformBox">
                        <Col span={4}>折扣 ( 单位 % : 95 即为 95% ) </Col>
                        <Col span={20}>
                            <Input name="discount"  onChange={onChangeDiscount} defaultValue={this.state.discount} />
                        </Col>
                    </Row>

                    <Row id="platformBox">
                        <Col span={4}>折后总价</Col>
                        <Col span={20} style={{textAlign: "left"}}>
                            {discountSumPirce}
                        </Col>
                    </Row>

                    <br/>
                
                    <input name="platform" value="" id="platform" type="hidden" />
                    <input name="sale_id" value="" id="sale_id" type="hidden" />
                    <input name="export_log_id" value="" id="export_log_id" type="hidden" />
                    <input name="is_save" value="0" id="is_save" type="hidden" />
					<input id="platformSubmit" type="submit" value="导出表格" onClick={onFinish} />
                    <br/>
                    <br/>
                    <input id="platformSave" type="button" value="保存" onClick={onSave} />
				</form>

			</div>
		)
	}
}