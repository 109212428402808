import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, Input, Button} from 'antd';

import ListApi from '../api/list';

import 'antd/dist/antd.css';

export default class List extends Component {

	constructor(props){
		super(props);
		this.state = { 
			list: [],
		}
	}

	async componentDidMount() { 
		ListApi.exportLog( response => {
			let list = [];
			for (let i in response.data) {
            	list[i] = response.data[i]
            	list[i].key = "list_" + i
            	list[i].id = list[i].id + ""
            }

			this.setState({ list: list || '' });
		});
	}

	render(){

		const columns = [
			{
			  title: '销售',
			  dataIndex: 'sale_name',
			  key: 'sale_name',
			  render: text => <span>{text}</span>,
			},
			{
				title: '标题',
				dataIndex: 'platform_title',
				key: 'platform_title',
			},
			{
				title: '折扣',
				dataIndex: 'discount',
				key: 'discount',
			},
			{
			  title: '时间',
			  dataIndex: 'date',
			  key: 'date',
			},
			{
			  title: '操作',
			  key: 'action',
			  render: (text, record) => {
				return <Link id="listedit" to={"/info/" + record.id}>修改</Link>
			  },
			},
		  ];

		return (
			<div id="list">
                <Table columns={columns} dataSource={this.state.list} />

				<Link id="listedit" to={"/info/0"}>创建表格</Link>

			</div>
		)
	}
}