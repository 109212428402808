import { post, get } from './index';

function allData(func) {
    get('/allData').then(response => {
        if(response){
            return func(response.data)
        }else{
            return false
        }
    });
}

function exportLog(func, export_log_id) {
    get('/exportLog?export_log_id=' + export_log_id).then(response => {
        if(response){
            return func(response.data)
        }else{
            return func('')
        }
    });
}

export default {
    allData,
    exportLog
}