import React, { Component } from 'react';
import { Card, Checkbox, Input, Button, Form, Space, Row, Col } from 'antd';

import homeApi from '../api/home';

import 'antd/dist/antd.css';

const { TextArea } = Input;

var platformListChecked = []
var projectListChecked = []
var moduleListChecked = []

var projectArray = []
var moduleArray = []
var moduleListsArray = []

export default class Home extends Component {

	constructor(props){
		super(props);
		this.state = { 
			platformList: [],
			platformListChecked: [],
			projectList: [],
			projectArray: [],
			projectListChecked:[],
			// moduleList: [],
			moduleArray: [],
			moduleListsArray: [],
			moduleListChecked: [],
			moduleValues: []
		}
	}

	async componentDidMount() { 
		homeApi.getPlatformList( response => {
			let platformList = [];
			for (let i in response.data) {
            	platformList[i] = response.data[i]
            	platformList[i].key = "platform_" + i
            	platformList[i].id = platformList[i].id + ""
				platformList[i].platformName = "【 平台 】" + platformList[i].name
            }

			this.setState({ platformList: platformList || '' });
		});
	}

	render(){

		// const getPlatformList = ( values ) => {
		// 	// 接口
		// 	// homeApi.getPlatformList( response => {
				
		// 	// }, values);
		// }

		// 
		
		const platformListCheckbox 	= []
		const platformListContent 	= []
		const projectListContent 	= []

		var platformData 	= {}

		// onChange fucntion
		const onChangePlatform = (e) => {
			if ( e.target.checked == true ) {
				platformListChecked.push(e)
				let platformId = e.target.value
				getProjectList(platformId)
			} else {
				let tempPlatformListChecked = []
				for (let i in platformListChecked ) {
					let item = platformListChecked[i]
					if ( item.target.value != e.target.value ) {
						tempPlatformListChecked.push(item)
					}
				}
				platformListChecked = tempPlatformListChecked
			}
			this.setState({ platformListChecked: platformListChecked || '' });
		}

		// 
		const onChangeProject = (e) => {
			let value = e.target.value
			if ( e.target.checked == true ) {
				projectListChecked[value] = e
				getModelList(value)
			} else {
				projectListChecked[value] = undefined
			}
			this.setState({ projectListChecked: projectListChecked || '' });
		}

		// 
		const onChangeModule = (e) => {
			let value = e.target.value
			if ( e.target.checked == true ) {
				moduleListChecked[value] = e
			} else {
				moduleListChecked[value] = undefined
			}

			let moduleValues = this.state.moduleValues
			let projectId = e.target.projectId
			let moduleArrayItem = this.state.moduleListsArray[projectId]

			if ( moduleArrayItem != undefined) {
				let moduleItem = moduleArrayItem[value]
				if ( moduleItem != undefined && moduleValues[value] == undefined ) {
					moduleValues[value] = {
						standard: moduleItem.standard,
						unit: moduleItem.unit,
						price: moduleItem.price,
						num: moduleItem.num,
						explain: moduleItem.explain,
					}
				}
			}

			this.setState({ moduleValues: moduleValues || '' });
			this.setState({ moduleListChecked: moduleListChecked || '' });
		}

		// function start
	  	const getProjectList = (value) => {
			homeApi.getProjectList( response => {
				let projectList = []
				for (var projectIndex in response.data) {
	            	projectList[projectIndex] = response.data[projectIndex]
	            	projectList[projectIndex].key = "project_".projectIndex
	            	projectList[projectIndex].id = projectList[projectIndex].id + ""
					projectList[projectIndex].projectName = "【 项目名称 】" + projectList[projectIndex].name
	            }
				projectArray[value] = projectList
				this.setState({ projectArray: projectArray || '' });
			}, value);
		}

		const getModelList = (value) => {
			homeApi.getModuleList( response => {
				let moduleList = []
				let moduleLists = []
				for (var moduleIndex in response.data) {
	            	moduleList[moduleIndex] = response.data[moduleIndex]
	            	moduleList[moduleIndex].key = "module_" + moduleIndex
	            	moduleList[moduleIndex].id = moduleList[moduleIndex].id + ""
					moduleList[moduleIndex].moduleName = "【 制作内容 】" + moduleList[moduleIndex].content

					let id = moduleList[moduleIndex].id
					moduleLists[id] = response.data[moduleIndex]
	            }

				moduleListsArray[value] = moduleLists
				this.setState({ moduleListsArray: moduleListsArray || '' });

				moduleArray[value] = moduleList
				this.setState({ moduleArray: moduleArray || '' });
			}, value);
	  	}

		let onChangeStandard = (e) => {
			let moduleid = e.target.getAttribute("moduleid")
			let value = e.target.value
			let moduleValues = this.state.moduleValues
			if ( moduleValues[moduleid] != undefined) {
				moduleValues[moduleid].standard = value
			} else {
				moduleValues[moduleid] = {
					standard: value
				}
			}
			
			this.setState({ moduleValues: moduleValues || '' });
		}

		let onChangeUnit = (e) => {
			let moduleid = e.target.getAttribute("moduleid")
			let value = e.target.value
			let moduleValues = this.state.moduleValues
			if ( moduleValues[moduleid] != undefined) {
				moduleValues[moduleid].unit = value
			} else {
				moduleValues[moduleid] = {
					unit: value
				}
			}
			
			this.setState({ moduleValues: moduleValues || '' });
		}

		let onChangePrice = (e) => {
			let moduleid = e.target.getAttribute("moduleid")
			let value = e.target.value
			let moduleValues = this.state.moduleValues
			if ( moduleValues[moduleid] != undefined) {
				moduleValues[moduleid].price = value
			} else {
				moduleValues[moduleid] = {
					price: value
				}
			}
			
			this.setState({ moduleValues: moduleValues || '' });
		}

		let onChangeNum = (e) => {
			let moduleid = e.target.getAttribute("moduleid")
			let value = e.target.value
			let moduleValues = this.state.moduleValues
			if ( moduleValues[moduleid] != undefined) {
				moduleValues[moduleid].num = value
			} else {
				moduleValues[moduleid] = {
					num: value
				}
			}
			
			this.setState({ moduleValues: moduleValues || '' });
		}

		let onChangeExplain = (e) => {
			let moduleid = e.target.getAttribute("moduleid")
			let value = e.target.value
			let moduleValues = this.state.moduleValues
			if ( moduleValues[moduleid] != undefined) {
				moduleValues[moduleid].explain = value
			} else {
				moduleValues[moduleid] = {
					explain: value
				}
			}
			
			this.setState({ moduleValues: moduleValues || '' });
		}

		// function end
		
		// platformList start
	  	for (let i = 0; i < this.state.platformList.length; i++) {
	  		let platformItem = this.state.platformList[i];
			let checked = false
	  		platformListCheckbox.push(
	  			<Col key={platformItem.key} span={8}>
			        <Checkbox value={platformItem.id} onChange={onChangePlatform} defaultChecked={checked} name={platformItem.name} platformName={platformItem.platformName}>{platformItem.name}</Checkbox>
			    </Col>
	  		)
	  	}
		
	  	platformListContent.push(
	  		<Row key="platformListContent_0">
	  			{platformListCheckbox}
	  		</Row>
	  	)
		// platformList end
		
		// 选中 platform 展示
		for (let j = 0; j < this.state.platformListChecked.length; j++) {

			let platformListCheckedItem = this.state.platformListChecked[j]
			let platformId = platformListCheckedItem.target.value
			let platformProjectItem = this.state.projectArray[platformId]

			//	projectList 判断
			let projectListCheckbox = []
			let moduleListContent = []
			if ( platformProjectItem != undefined ){	
				for (let y = 0; y < platformProjectItem.length; y++) {
					let projectItem = platformProjectItem[y]
					let projectId = projectItem.id
					let checked = this.state.projectListChecked[projectId] ? true : false
					projectListCheckbox.push(
						<Col key={projectItem.key} span={8}>
							<Checkbox value={projectItem.id} onChange={onChangeProject} defaultChecked={checked} name={projectItem.name} platformId={projectItem.platform_id} projectName={projectItem.projectName}>{projectItem.name}</Checkbox>
						</Col>
					)

					// moduleList 判断
					let moduleListCheckbox = []
					let moduleListCard = []
					let projectModuleItem = this.state.moduleArray[projectId]
					if ( projectModuleItem != undefined ){
						for (let k = 0; k < projectModuleItem.length; k++) {
							let moduleItem = projectModuleItem[k];
							let moduleId = moduleItem.id
							let checked = this.state.moduleListChecked[moduleId] ? true : false
							moduleListCheckbox.push(
								<Col key={moduleItem.key} span={8}>
									<Checkbox value={moduleItem.id} onChange={onChangeModule} defaultChecked={checked} content={moduleItem.content} projectId={moduleItem.project_id} moduleName={moduleItem.moduleName}>{moduleItem.content}</Checkbox>
								</Col>
							)

							let moduleListCheckedItem = this.state.moduleListChecked[moduleId]
							if( moduleListCheckedItem != undefined ) {
								// card
								moduleListCard.push(
									<div key={moduleListCheckedItem.target.value} >
										<Card id="moduleList" key={moduleListCheckedItem.target.value} title={moduleListCheckedItem.target.moduleName} >
											<Row>
												<Space>
												制作标准
													<TextArea rows={10} cols={50}  moduleid={moduleId} defaultValue={moduleItem.standard} onChange={onChangeStandard} />
												</Space>
											</Row>
											<br/>

											<Row>
											<Space>
												单位
												<Input placeholder="单位" moduleid={moduleId} defaultValue={moduleItem.unit} onChange={onChangeUnit} />
												单价
												<Input placeholder="单价" moduleid={moduleId} defaultValue={moduleItem.price} onChange={onChangePrice} />
												数量
												<Input placeholder="数量" moduleid={moduleId} defaultValue={moduleItem.num} onChange={onChangeNum} />
											</Space>
											</Row>

											<br/>

											<Row>
											<Space>
												补充说明
												<TextArea rows={10} cols={50}  moduleid={moduleId} defaultValue={moduleItem.explain} onChange={onChangeExplain}/>
											</Space>
											</Row>

										</Card>
										<br/>
									</div>
								)
							}
						}
					}

					// 选择 project 展示
					let projectListCheckedItem = this.state.projectListChecked[projectId]
					if( projectListCheckedItem != undefined ) {
						moduleListContent.push(
							<div  key={projectListCheckedItem.target.value}>
								<Card id="projectList"  key={projectListCheckedItem.target.value} title={projectListCheckedItem.target.projectName} >
									<Row key={projectListCheckedItem.target.value} >
										{moduleListCheckbox}
									</Row>
									<br/> 
									{moduleListCard}
								</Card>
								<br/>
							</div>
						)
					}
				}

				projectListContent.push(
					<div>
						<Card id="platformList" key={platformListCheckedItem.target.value} title={platformListCheckedItem.target.platformName}>
							<Row  key={platformListCheckedItem.target.value}>
								{projectListCheckbox}
							</Row>
							<br/> 
							{moduleListContent}
						</Card>
						<br/>
					</div>
				)
			}
		}

		let buttonType = "hidden"

		let platform = []
		for (let i = 0; i < this.state.platformListChecked.length; i++) 
		{
			let platformitem = this.state.platformListChecked[i]
			// 
			if ( platformitem != undefined && 
				platformitem.target.checked == true 
			) {
				let project = []
				for (let i = 0; i < this.state.projectListChecked.length; i++) 
				{
					let projectItem = this.state.projectListChecked[i]
					// 
					if ( projectItem != undefined && 
						projectItem.target.checked == true &&
						projectItem.target.platformId == platformitem.target.value 
					) {

						let module = []
						for (let i = 0; i < this.state.moduleListChecked.length; i++) 
						{
							let moduleItem = this.state.moduleListChecked[i]
							// 
							if ( moduleItem != undefined &&
								moduleItem.target.checked == true &&
								moduleItem.target.projectId == projectItem.target.value 
							) {

								buttonType = "submit"

								let moduleNode = this.state.moduleValues[moduleItem.target.value]
								let moduleValue = {}
								if ( moduleNode != undefined) {
									moduleValue = {
										id: moduleItem.target.value,
										content: moduleItem.target.content,
										standard: moduleNode.standard,
										unit: moduleNode.unit,
										price: moduleNode.price,
										num: moduleNode.num,
										explain: moduleNode.explain,
									}
								} else {
									moduleValue = {
										id: moduleItem.target.value,
										content: moduleItem.target.content,
										// project_id: moduleItem.target.projectId,
									}
								}
								module.push(moduleValue)
							}
						}

						if ( module.length > 0 ) {
							project.push({
								id: projectItem.target.value,
								name: projectItem.target.name,
								desc: "",
								module: module,
								// platform_id: projectItem.target.platformId,
							})
						}
					}
				}

				if ( project.length > 0 ) { 
					platform.push({
						id: platformitem.target.value,
						name: platformitem.target.name,
						project: project
					})
				}
			}
		}

			// 提交
		let onFinish = () => {
			platformData = JSON.stringify(platform)
			document.getElementById("platform").value = platformData
		}

		return (
			<div id="home">

				{platformListContent}
			  	<br />
			  	{projectListContent}
				<br />

				{/* <form action="https://productquotationapi.fzsdmjh5.com/export" method="post" > */}
				<form action="http://127.0.0.1:8000/export" method="post" >
					<input name="platform" value="" id="platform" type="hidden" />
					<br/>
					<input id="platformSubmit" type={buttonType} value="导出表格" onClick={onFinish} />
				</form>

			</div>
		)
	}
}