import axios from 'axios';

let BASE_URL;
let export_url;

if(process.env.NODE_ENV == 'development'){
    BASE_URL = 'http://localhost:8000'
    export_url = "http://127.0.0.1:8000/export"
}else{
    BASE_URL = 'https://productquotationapi.fzsdmjh5.com'
    export_url = "https://productquotationapi.fzsdmjh5.com/export"
}

//拦截器，在向后台发出请求的时候，可以动态的修改配置
axios.interceptors.request.use((config) => {
    let jwtToken = window.localStorage.getItem('projectJwtToken');
    if (jwtToken) {
        config.headers.authorization = `Bearer ${jwtToken}`;
    }
    // console.log("jwtToken", jwtToken);
    return config;
});

axios.interceptors.response.use(res => {    
    // if (res.data.code !== 0) {
    //     return Promise.reject(res.data.error);
    // }
    return res;
}, error => {
    if ( error.response && error.response.status == 401 ) {
        window.localStorage.setItem('projectJwtToken', '');
        window.localStorage.setItem('sale_id', '');
        window.location.href = "/"
    }

    // if (error.response && error.response.status >= 400 && error.response.status > 500) {
    // }
    // return Promise.reject(error.response.data.message);
});

export async function get(url) {
    return await axios.get(BASE_URL + url);
}

export async function post(url, body) {
    return await axios.post(BASE_URL + url, body);
}